@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto+Slab:400,700|Roboto:300,400,700|Syncopate:400,700&display=swap&subset=cyrillic");
@import "~video-react/styles/scss/video-react";

@mixin ellipsis($nowrap: true) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if $nowrap {
    white-space: nowrap;
  }
}

.one-line-ellipsis {
  display: block !important;
  @include ellipsis;
}

@mixin ellipsis-multiline($lines) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

:root {
  --mainBlack: #232323;
  --success: #009118;
  --error: #f44336;
  --primaryMUI: #223a68;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  display: flex;
  flex-direction: column;
  height: auto;
  font-family: "Roboto", sans-serif;
}

/* Scroll OSLike FF IE unsupport */
::-webkit-scrollbar {
  background-color: #fff;
  width: 2px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 4px;
  border: 1px solid #fff;
}

::-webkit-scrollbar-button {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0 1000px transparent;
  transition: background-color 5000s ease-in-out 0s;
}

a {
  color: blue; // было transparent
  text-decoration: none;
}

img {
  max-width: 100%;
}

.wrap {
  margin: 0 auto;
  width: 980px;
}

.pageTitle {
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0.13px;
  color: #484848;
  padding-bottom: 5px;
  border-bottom: 1px solid #959595;
  margin: 20px 20px 15px;
}

.global-title {
  color: var(--mainBlack);
  font-size: 24px;
  font-weight: 700;
}

.snackbar_success {
  .MuiSnackbarContent-root {
    background-color: var(--success);
  }
}

.snackbar_error {
  .MuiSnackbarContent-root {
    background-color: var(--error);
  }
}

.MuiSnackbarContent-root {
  svg {
    color: #fff;
  }
}

.d-none,
input[type="file"] {
  display: none !important;
}

.d-block {
  display: block !important;
}

.MuiGridListTile-imgFullHeight {
  object-fit: contain !important;
}

.text-center {
  text-align: center !important;

  @media screen and (max-width: 970px) {
    font-size: 14px;
  }
}

@keyframes show {
  to {
    opacity: 1;
  }
}

strong {
  font-weight: 700;
}

.MuiDialog-paperFullWidth {
  @media screen and (max-width: 550px) {
    width: 97% !important;
  }
}

.MuiDialog-paper {
  @media screen and (max-width: 550px) {
    margin: 3px !important;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.error {
  color: var(--error) !important;
}

.video-dialog {
  .MuiPaper-root {
    max-width: 1520px !important;
  }
}

.feedShare-more__popover {
  .feed-user-info {
    margin-left: 0 !important;
    margin-bottom: 3px;
  }
  .MuiPaper-root {
    min-width: 260px;
    min-height: 200px;
    padding: 5px;
  }
}

.MuiInputBase-input {
  box-sizing: content-box !important;
}

.dialogs-empty-results {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.dialog-infinite-scroll-list {
  padding: 5px 15px;
}

.dialog-users-list__label {
  margin: 0 0 15px 0;

  .MuiFormControlLabel-label {
    display: flex !important;
    align-items: center;
    margin-left: 5px;
  }
}

b {
  font-weight: 700;
}

.formattedString {
  white-space: pre-line;
}

#manageFeedAccessContactsList {
  height: 300px;
  overflow: auto;
}

.fullWidth {
  width: 100%;
}
.fullGridRow {
  grid-column: 1/-1;
}
.infinite-scroll-component {
  li {
    list-style: none;
  }
}
